<template>
  <section ref="section"
           class="-mt-px"
           :class="[{'h-[500vh] relative': !size.isMobile}]"
  >
    <div ref="container"
         :class="[{'flex sticky top-0': !size.isMobile}]"
    >
      <div class="flex-[0_0_auto]">
        <div :class="`bg-${backgroundColor}`"
             class="flex flex-col w-screen h-screen justify-evenly"
        >
          <div class="md:max-w-[60vw] mx-auto text-[10vw] leading-[10vw] md:text-[4.5vw] md:leading-[4.5vw] font-work font-extrabold text-center px-[6vw] md:flex justify-center"
               :class="(backgroundColor === 'toxic' || backgroundColor === 'lemon') ? 'text-indigo' : 'text-white'"
          >
            {{ friendsTitle }}
          </div>
          <div class="flex flex-wrap-reverse justify-center">
            <NuxtImg v-for="(image, index) in friendsList"
                      format="auto"
                      :key="index"
                      :src="image"
                      loading="lazy"
                      sizes="xs:30vw sm:30vw md:12.5vw lg:12.5vw xl:12.5vw xxl:12.5vw"
                      class="md:w-[12.5vw] w-[30vw]"
            />
          </div>
        </div>
      </div>
      <div class="flex-[0_0_auto]">
        <SectionCharacterFriendsSlide :background-color="petsColor"
                                      :image-mobile="petsImageMobile"
                                      :image-desktop="petsImageDesktop"
                                      :title="petsTitle"
                                      md-width="68"
        />
      </div>
      <div class="flex-[0_0_auto]">
        <SectionCharacterFriendsSlide background-color="purple"
                                      :image-mobile="rakoonsImageMobile"
                                      :image-desktop="rakoonsImageDesktop"
                                      :title="rakoonsTitle"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { animate, scroll } from 'motion';
import { ref, onMounted } from 'vue';
import { screenSize } from '~/composables/screen-size';

type SectionCharacterFriends = {
  backgroundColor: string;
  color: string;
  friendsTitle: string
  friendsList: string[]
  petsTitle: string
  petsColor: string;
  petsImageMobile: string
  petsImageDesktop: string
  rakoonsTitle: string
  rakoonsImageMobile: string
  rakoonsImageDesktop: string
}

defineProps<SectionCharacterFriends>();

const section = ref<HTMLElement>();
const container = ref<HTMLElement>();
const size = screenSize();

onMounted(() => {
  if(!size.value.isMobile) {
    const items = container.value.children;
    // Animate gallery horizontally during vertical scroll
    scroll(
      animate(container.value, {
        transform: ['none', `translateX(-${items.length - 1}00vw)`]
      }),
      { target: section.value }
    );
  }
});
</script>

<style>
body {
  overflow-x: hidden;
}
</style>
